<template>
  <div>
    <div class="" id="main-div" > 
      <vs-table ref="table" stripe pagination :max-items="itemsPerPage" search :data="agents" noDataText="No hay datos disponibles">

        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center data-list-btn-container mr-4">
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-add" @click="addAgentRequest">
              Agregar vendedor
            </vs-button>
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ agents.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : agents.length }} de {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="itemsPerPage=it" :key="it">
                <span>{{it}}</span>
              </vs-dropdown-item>            
            </vs-dropdown-menu>
          </vs-dropdown>

        </div>

        <template slot="thead">
          <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ tr.email }}</vs-td>
            <vs-td>{{ tr.phoneModel ? `${tr.phoneModel.country_calling_code} ${phoneFormat(tr.phoneModel.phone)}` : phoneFormat(tr.phone) }}</vs-td>
            
            <vs-td v-if="tr.last != false">{{ tr.last }}</vs-td>
            <vs-td v-else>Inactivo <a @click="sendEmail(tr)" class="link">reenviar correo</a></vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editAgentRequest(tr)" />
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-6" @click.stop="deleteAgentRequest(tr)" />
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        :title="formPromptTitle"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert class="h-full mb-5" :color="onSaveError ? 'danger' : 'warning'">
            <span>{{this.onSaveError ? formMssg : formPromptIndication}}</span>
          </vs-alert>

          <vs-input
           class="w-full mb-4" 
           label="Nombre completo"
           placeholder="Requerido"
           v-validate="'required'"
           data-vv-name="name"
           v-on="{input: e => {formAgent.name = upper(e) }}"
           :danger="hasError('name')"
           :danger-text="errorText('name')"
           :success="isSuccess('name')"
           v-model.lazy="formAgent.name" />  
           
           <div class="flex">
            <vue-country-code
                name="dialCode"
                class="vs-custom dial-code"
                data-vv-name="phone"
                :enableSearchField="true"
                searchPlaceholderText="Buscar código de país"
                :enabledCountryCode="true"
                @onSelect="onSelect"
                :defaultCountry="formAgent.preferredCountries"
                :preferredCountries="['MX']"
                :danger="hasError('dialCode')"
                :danger-text="errorText('dialCode')"
                :success="isSuccess('dialCode')">
              </vue-country-code>

              <vs-input
               class="w-full mb-4" 
               label="Celular"
               type="tel"
               placeholder="Requerido"
               v-validate="'required|digits:10'"
               data-vv-name="phone"
               icon-pack="feather"
               icon="icon-phone"
               :danger="hasError('phone')"
               :danger-text="errorText('phone')"
               :success="isSuccess('phone')"
               v-model.lazy="formAgent.phone" />             
           </div>


          <vs-input
           v-if="formMode == 'new'"
           class="w-full mb-4" 
           label="Correo electrónico"
           placeholder="Requerido"
           v-validate="'required|email'"
           data-vv-name="email"
           :danger="invalidEmail || hasError('email')"
           :danger-text="invalidEmail ? 'El correo está siendo utilizado por otro usuario' : errorText('email')"
           :success="isSuccess('email') && !invalidEmail"
           v-model.lazy="formAgent.email" />

          <vs-button @click="saveFormAgent" color="primary" class="mr-5 mt-2">
            {{this.formMode == 'edit' ? 'Actualizar' : 'Agregar'}}
          </vs-button>

          <vs-button @click="closeFormPrompt" type="flat" color="primary">Cancelar</vs-button>
           
        </div>
      </vs-popup>

      <!-- Asignar proyectos al vendedor -->
      <vs-popup
        title="Selección de otro vendedor para asignar proyectos"
        :active.sync="showAssignAgentPrompt">
        <div class="con-exemple-prompt">
          <p class="mb-5 bold">Seleccione al vendedor que tendrá asignados los proyectos del vendedor {{deletingAgentName}}.</p>
          <vx-select class="w-full mb-5" 
            label="Lista de vendedores disponibles"
            v-model.lazy="agent_id"
            name="agent_id"
            :disabled="getAgentsList.length < 1"
            :placeholder="getAgentsList.length < 1 ? noDataPlaceholder : selectPlaceholder" >
            <vx-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in getAgentsList" class="w-full" />
          </vx-select>
          <!-- <label for="" class="vs-input--label">Lista de vendedores disponibles</label>
          <v-select
            name="agent_id"
            class="vs-custom w-full mb-5"
            v-model.lazy="agent_id"
            :options="getAgentsList"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            :placeholder="getAgentsList.length < 1 ? noDataPlaceholder : selectPlaceholder"
            :disabled="getAgentsList.length < 1"
            @input="onFieldChange()"        
          >
          <div slot="no-options">No hay coincidencias</div> -->
          <!-- </v-select> -->
          <vs-button :disabled="!agent_id" @click="assignAgent" color="primary" class="mr-5 mt-2">Aceptar y eliminar</vs-button>
          <vs-button @click="closeAgentPrompt" type="flat" color="primary">Cerrar</vs-button>
        </div>
      </vs-popup>

    </div>
    <load-error  v-if="failed"/>
  </div>

</template>

<script>
import formatHelper from '@mixins/formatHelper';
import inputHelper from "@mixins/inputHelper";

const columnDefinitions = [
  { title: "NOMBRE COMPLETO", key: "NAME", sortable: true },
  { title: "CORREO ELECTRÓNICO", key: "email", sortable: true },
  { title: "TELÉFONO", key: "phone", sortable: true },
  { title: "ÚLTIMA SESIÓN", key: "last", sortable: false },
  { title: "ACCIONES", key: "delete", sortable: false }
];

const itemsPerPageDefinitions = [5,10,25,50];

export default {
  name: "agents-list",
  props: [],
  mixins: [formatHelper, inputHelper],
  components: {
  },
  async created() {
    await this.loadAgents();
  },
  data() {
    return {
      agents: [],
      allAgents : [],
      orderKey: "",
      projectIdToDelete: -1,
      columns: columnDefinitions,
      filtersSelected: [],
      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[0],
      isMounted: false,
      loading: false,
      failed: false,
      onSaveError: false,

      showFormPrompt: false,      
      resolutionClientCause: null,
      resolutionCause: "",
      deletingAgent: null,
      agentSelectedToEdit: null,

      formAgent: {
        id: null, 
        name: null,
        phone: null,
        email: null,
        dialCode: null,
        preferredCountries: '',
      },
      formMode: null, // edit | new
      formMssg: null,
      invalidEmail: false,
      
      agent_id: null,
      selectPlaceholder: "Seleccione una opción",
      noDataPlaceholder: "No hay opciones disponibles",
      getAgentsList: [],
      showAssignAgentPrompt: null,
      deletingAgentName: null,
    };
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.agents.length
    },
    formPromptTitle(){
      return this.formMode == 'edit' ? "Editar vendedor" : "Agregar vendedor";
    },
    formPromptIndication(){
      return this.formMode == 'edit' ? "Datos a modificar" :  "Por favor ingrese todos los datos";
    },
  },
  watch: {
    loading: function(){
      if(this.loading) {
        this.$vs.loading();        
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
  methods: {
    onSelect({ dialCode }) {
      this.formAgent.dialCode = `+${dialCode}`;
    },
    sendEmail(agent){
      this.loading = true
      axios.post('/api/supplier/agents/email', {
        id: agent.id,
      }).then(response => {
        this.loading = false
        let message_info = 'Correo enviado a '+ agent.email;
        this.successNotif("Operación exitosa", message_info, 6000);
      }).catch(error => {
        this.failedOperationNotif();
        this.loading = false
      });
    },
    closeAgentPrompt() {
      this.showAssignAgentPrompt = false
    },
    async assignAgent(){
      this.loading = true;
      try {
        let payload = {deleting_agent_id: this.deletingAgent.id, new_agent_id: this.agent_id};
        const res = await axios.post('/api/v1/supplier/deleteAgentAndReassignProjects', payload);
        // console.log(res);
        this.closeAgentPrompt();
        await this.loadAgents();
        this.saveSuccessNotif();
      }
      catch (error) {
        this.warn(error);
        this.failedOperationNotif();  
      }
      this.loading = false;
    },
    async loadAgents(){
      this.isMounted = false;
      this.loading = true;
      this.failed = false;

      try {
        let response = await axios.get("/api/supplier/agents");
        this.agents = this.allAgents = response.data;
      } catch (e) {
        this.failed = true;
      } 
      this.isMounted = true;
      this.loading = false;    
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    deleteAgentRequest (data) {
      this.deletingAgent = data;
      this.getAgentsList = [];
      let mssg = "¿Realmente desea eliminar al vendedor " + data.name + "? ";
      if(this.deletingAgent.projects.length > 0){
        mssg += `El vendedor tiene ${this.deletingAgent.projects.length} proyectos asignados, 
        y deberá seleccionar a otro vendedor al que se le asignarán dichos proyectos para poder continuar. `;

        this.allAgents.forEach(f => {
          if(f.id != this.deletingAgent.id){
            this.getAgentsList.push({value: f.id, text: f.name});
          }
        });
      }
      mssg += "El registro no podrá ser recuperado posteriormente."

      let action = this.deletingAgent.projects.length > 0 ? this.openSelectAgentPrompt : this.doDeleteAgent;
      this.deletingAgentName = this.deletingAgent.name;
      
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar vendedor',
        text: mssg,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: action
      });
    },
    editAgentRequest(data){
      // precargar datos opciones
      this.formAgent.name = data.name;
      this.formAgent.phone = data.phoneModel ? data.phoneModel.phone : data.phone;
      this.formAgent.preferredCountries = data.phoneMode ? data.phoneModel.country_calling_code : 'MX';
      this.formAgent.email = data.email;
      this.formAgent.id = data.id;

      this.agentSelectedToEdit = data;
      this.formMode = 'edit';
      this.showFormPrompt = true;
      this.invalidEmail = false;
    },
    addAgentRequest(){
      this.formMode = 'new';
      this.resetFormAgent();
      this.showFormPrompt = true;
      this.invalidEmail = false;
    },
    async doDeleteAgent(){
      this.loading = true;
      try {
        await axios.post('/api/supplier/agents/delete', this.deletingAgent);
        this.deletingAgent = {};
        await this.loadAgents();
        this.successNotif("Vendedor elminado", "El registro ha sido eliminado exitosamente");
      } catch (error) {
        this.failedOperationNotif(null, "Antes de eliminar a este agente, asegurate que no tenga prospectos del programa asignados.");
      }
      this.loading = false;      
    },
    openSelectAgentPrompt(){
      this.showAssignAgentPrompt = true;
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },
    async saveFormAgent(){
      this.invalidEmail = false;
      this.onSaveError = false;
      let res = await this.$validator.validateAll();
      if(!res){
        this.onSaveError = true;
        this.formMssg = "Debe llenar todos los campos";
        return;
      }
      
      this.loading = true;
      try {
        if(this.formMode == 'new'){
          // alta de vendedor
          let data = { email : this.formAgent.email };
          let res = await this.publicApiPost('/api/login/mail', data);
          
          if(res.data){
            this.onSaveError = true;
            this.formMssg = "El correo ingresado ya pertenece a un usuario, por favor intenta con otro";
            this.invalidEmail = true;
          }
          else {
            let cre = await this.createAgent();
            if(!cre){
              this.onSaveError = true;
              this.formMssg = "Ha ocurrido un error con la operación, intente más tarde";
            }
            else{
              await this.loadAgents();
              this.successNotif("Vendedor agregado", "El vendedor ha sido registrado exitosamente");
              this.closeFormPrompt();
            }
          }
        }
        else {
          // actualizacion
          let upd = await this.updateAgent();
          if(!upd){
            this.onSaveError = true;
            this.formMssg = "Ha ocurrido un error con la operación, intente más tarde";
          }
          else{
            await this.loadAgents();
            this.successNotif("Vendedor agregado", "El vendedor ha sido registrado exitosamente");
            this.closeFormPrompt();
          }
        }
      }
      catch (error) {
        this.onSaveError = true;
        this.formMssg = "Ha ocurrido un error con la operación, intente más tarde";
      }
      this.loading = false;
    },
    async createAgent(){
      let data = {
        name: this.formAgent.name,
        email: this.formAgent.email,
        phone: this.formAgent.phone,
        country_calling_code: this.formAgent.dialCode
      }
      let ok = false;

      try {
        await axios.post('/api/supplier/agents', data);   
        ok = true;
      } catch (error) {
        ok = false;
      }

      return ok;
    },
    async updateAgent(){
      let data = {
        id: this.formAgent.id,
        name: this.formAgent.name,
        phone: this.formAgent.phone,
        country_calling_code: this.formAgent.dialCode
      }
      let ok = false;

      try {
        await axios.post('/api/supplier/agents/edit', data);      
        ok = true;
      } catch (error) {
        ok = false;
      }

      return ok;
    },
    closeFormPrompt() {
      this.formMode = null;
      this.onSaveError = false;
      this.formMssg = null;
      this.showFormPrompt = false;
      this.invalidEmail = false;
      this.resetFormAgent();
    },
    resetFormAgent(){
      this.formAgent.id = null;
      this.formAgent.name = null;
      this.formAgent.phone = null;
      this.formAgent.email = null;
    }
  },
};
</script>

